import React from "react"
import "../pages/styles.scss"
import Header from "../components/header"

const Blog  = () => {
    return (
        <div>        
           <Header/>
        </div>
    )
}


export default Blog